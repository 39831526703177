/* iCheck plugin Minimal skin, blue
----------------------------------- */
.icheckbox_minimal-blue,
.iradio_minimal-blue {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 18px;
    height: 18px;
    background: url(blue.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_minimal-blue {
    background-position: 0 0;
}
    .icheckbox_minimal-blue.hover {
        background-position: -20px 0;
    }
    .icheckbox_minimal-blue.checked {
        background-position: -40px 0;
    }
    .icheckbox_minimal-blue.disabled {
        background-position: -60px 0;
        cursor: default;
    }
    .icheckbox_minimal-blue.checked.disabled {
        background-position: -80px 0;
    }

.iradio_minimal-blue {
    background-position: -100px 0;
}
    .iradio_minimal-blue.hover {
        background-position: -120px 0;
    }
    .iradio_minimal-blue.checked {
        background-position: -140px 0;
    }
    .iradio_minimal-blue.disabled {
        background-position: -160px 0;
        cursor: default;
    }
    .iradio_minimal-blue.checked.disabled {
        background-position: -180px 0;
    }

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
       only screen and (-moz-min-device-pixel-ratio: 1.5),
       only screen and (-o-min-device-pixel-ratio: 3/2),
       only screen and (min-device-pixel-ratio: 1.5) {
    .icheckbox_minimal-blue,
    .iradio_minimal-blue {
        background-image: url(blue@2x.png);
        -webkit-background-size: 200px 20px;
        background-size: 200px 20px;
    }
}