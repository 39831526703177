/* iCheck plugin Flat skin, blue
----------------------------------- */
.icheckbox_flat-blue,
.iradio_flat-blue {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 2px;
    padding: 0;
    width: 23px;
    height: 22px;
    background: url(input-bg.png) no-repeat;
    border: none;
    cursor: pointer;
}
.ffs-invoice-table td{position: relative;}
.ffs-invoice-table .icheckbox_flat-blue{
    background: none;
}
.ffs-invoice-table .icheckbox_flat-blue .icheckbox_flat-blue{
   background: url(input-bg.png) no-repeat;
   
}
.ffs-invoice-table .icheckbox_flat-blue .icheckbox_flat-blue.checked{
        background-position: -22px 0;
}

.icheckbox_flat-blue {
    background-position: 0 0;
}
    .icheckbox_flat-blue.checked {
        background-position: -22px 0;
    }
    .icheckbox_flat-blue.disabled {
        background-position: -22px 0;
        cursor: default;
    }
    .icheckbox_flat-blue.checked.disabled {
        background-position: -66px 0;
    }

.iradio_flat-blue {
    background-position: -88px 0;
}
    .iradio_flat-blue.checked {
        background-position: -110px 0;
    }
    .iradio_flat-blue.disabled {
        background-position: -132px 0;
        cursor: default;
    }
    .iradio_flat-blue.checked.disabled {
        background-position: -154px 0;
    }

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
       only screen and (-moz-min-device-pixel-ratio: 1.5),
       only screen and (-o-min-device-pixel-ratio: 3/2),
       only screen and (min-device-pixel-ratio: 1.5) {
    .icheckbox_flat-blue,
    .iradio_flat-blue {
        background-image: url(blue@2x.png);
        -webkit-background-size: 176px 22px;
        background-size: 176px 22px;
    }
}